<template>
    <div>
        <base-list :collection='collection' :loading='loading' :fields='fields' :modalObject='currentModalObject' @pageChanged='pageChanged'>
            
            <template v-slot:cell(balance)='data'>
                <div v-for='(financialAccount, index) in data.item.financial_branch_accounts' :key='index'>
                    <div class='badge badge-light'>
                        {{ financialAccount.branch ? financialAccount.branch.name : '' }}
                    </div>
                    <div v-if='financialAccount.balance < 0' class='badge badge-danger'>
                        {{ financialAccount.balance | currency}}
                    </div>
                    <div v-if='financialAccount.balance > 0' class='badge badge-success'>
                        {{ financialAccount.balance | currency}}
                    </div>
                    <div v-if='financialAccount.balance == 0' class='badge badge-light'>
                        {{ 0 | currency }}
                    </div>
                </div>
            </template>

			<template v-slot:cell(actions)='data'>
				<button class='btn btn-xs btn-primary m-2' @click="modal('cashPaymentForm', {entityType: 'Vendor', entityId: data.item.id}, 'refresh')">Create Cash Payment</button>
				<button class='btn btn-xs btn-primary m-2' @click="modal('vendorForm', {id: data.item.id}, 'refresh')">Edit</button>
            </template>
        </base-list>
    </div>
</template>

<script>
import BaseList from './BaseList.vue';
import BaseListMethods from './BaseListMethods';

export default {
	props: ['viewType'],
    components: { BaseList },
    extends: BaseListMethods,
    data() {
        return {
			fields: [
                { key: 'id', label: 'ID' },
                { key: 'name', label: 'Name' },
                { key: 'balance', label: 'Balance' },
                { key: 'actions', label: 'Actions' },
			]
        }
    },
	methods: {
        load: function (apiClient, cancelToken, filter) {
			return apiClient.get('vendors', filter, cancelToken);
		}
    },
    watch: {

		// viewType: {
		// 	immediate: true,
		// 	handler: function () {
		// 	}
		// },
    }
}
</script>